import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function capitalize(str) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

const countryFormat = {
  México: 'es-MX',
  Colombia: 'es-CO',
  default: 'es-MX',
};

const dateFormatter = (date, timeZone, country) => {
  const countryLocaleString = countryFormat[country] || 'es-MX';
  const localTime = dayjs(date).tz(timeZone);
  const dayWeek = capitalize(new Date(date).toLocaleString(countryLocaleString, { timeZone, weekday: 'long' }));
  const month = capitalize(new Date(date).toLocaleString(countryLocaleString, { timeZone, month: 'long' }));
  const day = new Date(date).toLocaleString(countryLocaleString, { timeZone, day: '2-digit' });
  const hours = localTime.get('hour');
  const hoursPM = new Date(date).getHours();
  const minutes = new Date(date).getMinutes() === 0 ? '00' : new Date(date).getMinutes();
  const timeSuffix = hoursPM >= 12 ? 'pm' : 'am';
  return `${dayWeek}, ${day} de ${month} - ${hours}:${minutes} ${timeSuffix}`;
};
export default dateFormatter;
