/* eslint-disable */

import React, { useContext, useState, useEffect, useCallback } from 'react';
import ThankYouContext from '../../../../../../../../context/ThankYou/Context';
import PaymentContext from '../../../../../../../../context/Payment/Context';
import ProductContext from '../../../../../../../../context/Product/Context';
import GlobalContext from '../../../../../../../../context/Global/Context';
import useMediaQuery from '../../../../../../../../hooks/useMediaQuery';
import organizeDataPayments from '../../../../../../../../methods/organizeDataPayments';
import { getTotalPrice, getNumericPrice, getIntPrice, getNumberWithDecimals } from '../../../../../../../../utils/cardForm';
import { axiosPutRecalculateCarts } from '../../../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../../../utils/stock';
import styles from '../../../Culqui/Culqi.module.css';
import { Banner, PageLoader } from '@mymoons/ui-library';
import { axiosCreateCharge, axiosCreateOrder } from '../../../../../../../../methods/culqi/culqi';
import totalPriceCarts from '../../../../../../../../hooks/totalCarts';

const CulqiForm = () => {
  const paymentContext = useContext(PaymentContext);
  const thankYouContext = useContext(ThankYouContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const { hasAddress, hasMultiplePayments, setTotalPrice, setHasMultiplePayments, address } = paymentContext;
  const {
    country,
    email,
    setEmptyAddressFunction,
    isCitaFlow,
    isOnlyAdvance,
    isOnlyComplementaryPayment,
    phone
  } = globalContext;
  const cartsCheck = new URLSearchParams(window.location.search).getAll('cart');
  const totalPrice = getNumberWithDecimals(paymentContext.totalPrice) * 100;
  const name = localStorage.getItem('name');
  const { carts } = productContext;
  const [firstName = '', lastName = ''] = name.split(' ');
  const { setDataCulqi, setDataCulqiCuotealo } = thankYouContext;
  const dataCulqidCheckout = organizeDataPayments();
  const { payer, salesAgentId, nameAgent, clinicId } = dataCulqidCheckout;
  const [preventModal, setPreventModal] = useState(false);
  const [isCulqiInitialized, setIsCulqiInitialized] = useState(false);
  const [errorPaymentCulqi, setErrorPaymentCulqi] = useState(null);
  const [culqiToken, setCulqiToken] = useState(null);
  const [enableContainer, setEnableContainer] = useState(true);
  const [culqiOrderId, setCulqiOrderId] = useState(null);
  const [culqiOrder, setCulqiOrder] = useState(null);
  const [device, setDevice] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseCardMessage, setResponseCardMessage] = useState('');
  const [showResponsePanel, setShowResponsePanel] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    REACT_APP_CULQI_PUBLIC_KEY,
  } = process.env;

  const checkErrors = () => {
    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          cartsCheck
        );

        if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
          setPreventModal(true);
        } else {
          setPreventModal(false);
        }
      }
      catch (e) {
        console.error(e);
        setPreventModal(false);
      }
    })();
  };

  Culqi3DS.publicKey = `${REACT_APP_CULQI_PUBLIC_KEY}`;

  useEffect(() => {
    if (!totalPrice || !email || !firstName || !lastName || !address?.Phone) {
      console.error('Missing data, order creation not initiated.');
      return;
    }
    (async () => {
      const data = {
        totalPrice,
        email,
        firstName,
        lastName,
        phone: address?.Phone,
        cartId: carts[0]?.id,
        payer,
        businessSource: 'moons',
        salesAgentId,
        nameAgent,
        clinicId
      };

      try {
        const res = await axiosCreateOrder(data);
        setCulqiOrderId(res?.data?.id);
        setCulqiOrder(res?.data);
        setDataCulqiCuotealo(res?.data);
      } catch (error) {
        console.error('Error creating order:', error);
      }
    })();
  }, [totalPrice, email, firstName, lastName, hasAddress]);

  useEffect(() => {
    if (!totalPrice || !email || !firstName || !lastName || !address?.Phone) {
      console.error('Missing data Culqi 3ds cannot be initialized.');
      return;
    }

    (async () => {
      const deviceId = await Culqi3DS.generateDevice();
      setDevice(deviceId);

      if (!deviceId) {
        console.error("Ocurrio un error al generar el deviceID");
      }
    })();
  }, [totalPrice, email, firstName, lastName, hasAddress]);

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [totalPrice, hasAddress, device, culqiOrderId, culqiToken]);

  const validationInit3DS = ({ email, tokenId }) => {
    Culqi3DS.settings = {
      charge: {
        totalAmount: totalPrice,
        returnUrl: `${window.location.origin}/payment/culqi`,
      },
      card: {
        email,
      }
    };
    Culqi3DS.initAuthentication(tokenId);
  };

  const handleMessage = async (event) => {
    if (event.origin === window.location.origin) {
      const { parameters3DS, error } = event.data;

      if (parameters3DS) {
        try {
          const charge = {
            totalPrice,
            email,
            token: culqiToken,
            firstName,
            lastName,
            cartId: carts[0]?.id,
            payer,
            businessSource: 'moons',
            salesAgentId,
            nameAgent,
            clinicId,
            phone: address?.Phone,
            device,
            auth: {...parameters3DS}
          };

          const secondChargeCreated = await axiosCreateCharge(charge);
          if (secondChargeCreated.status === 201) {
            Culqi3DS.reset();
          } else {
            if (hasMultiplePayments && carts[0].cartState === 'Active') {
              const remanent = totalPriceCarts(carts, false) - totalPrice;
              setHasMultiplePayments(true);
              setTotalPrice(`$${remanent}`);
              window.location.reload();
              return;
            }
            setDataCulqi(secondChargeCreated.data);
            const tyData = {};
            localStorage.setItem('tyDataCard', JSON.stringify(tyData));
            window.location.replace(`${window.location.origin}/payment/culqi`);
            Culqi3DS.reset();
          }
        } catch (error) {
          console.error('Error processing charge:', error);
          Culqi3DS.reset();
        }
      }

      if (error) {
        console.error("Ocurrió un error: ", error);
      }
    }
  };

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const initializeCulqi = useCallback(() => {
    checkErrors();
    if (!isCitaFlow && !isOnlyAdvance && !isOnlyComplementaryPayment) {
      if (!hasAddress) {
        setEmptyAddressFunction(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
    }

    if (country !== 'MX') {
      if (!hasAddress) {
        setEmptyAddressFunction(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
    }
    if (!culqiOrderId) {
      setLoader(true)
      return;
    }
    setLoader(false)
      const settings = {
        title: 'Moons',
        currency: 'PEN',
        amount: totalPrice,
      };

      if (culqiOrderId) {
        settings.order = culqiOrderId
      }

      const client = {
        email,
      };

      const paymentMethods = {
        yape: totalPrice <= 50000,
        tarjeta: true,
        cuotealo: ((totalPrice >= 10000 && totalPrice <= 700000) && (!hasMultiplePayments)),
      };

      const options = {
        lang: 'auto',
        installments: false,
        modal: false,
        container: "#culqi-container",
        paymentMethods: paymentMethods,
      };

      const appearance = {
        menuType: "default",
        hiddenCulqiLogo: false,
        hiddenBannerContent: false,
        hiddenBanner: false,
        hiddenToolBarAmount: false,
        rules: {
          ".Culqi-Main-Container": {
            height: "40em",
            width: "100%",
          }
        }
      };

      const config = {
        settings,
        client,
        options,
        appearance
      };

      const publicKey = `${REACT_APP_CULQI_PUBLIC_KEY}`;
      const Culqi = new CulqiCheckout(publicKey, config);

      const handleCulqiAction = async () => {
        if (Culqi.token) {
          const token = Culqi.token.id;
          setEnableContainer(false);
          setCulqiToken(token);
        } else if (Culqi.order) {
          const tyData = {};
          setEnableContainer(false);
          localStorage.setItem('tyDataCard', JSON.stringify(tyData));
          window.location.replace(`${window.location.origin}/payment/culqi-cuotealo`);
        } else {
          console.error('Culqi Error : ', Culqi.error);
        }
      };

      Culqi.culqi = handleCulqiAction;
      Culqi.open();
  }, [culqiOrderId, totalPrice, hasAddress]);

  useEffect(() => {
    if (culqiToken) {
      (async () => {
        try {
          const charge = {
            totalPrice,
            email,
            token: culqiToken,
            firstName,
            lastName,
            cartId: carts[0]?.id,
            payer,
            businessSource: 'moons',
            salesAgentId,
            nameAgent,
            clinicId,
            phone: address?.Phone,
            device
          };
          const firstChargeCreated = await axiosCreateCharge(charge);
          if (firstChargeCreated.status === 200) {
            if (firstChargeCreated.data.action_code === "REVIEW") {
              validationInit3DS({ email, tokenId: culqiToken });
            } else {
              setDataCulqi(firstChargeCreated.data);
              if (hasMultiplePayments && carts[0].cartState === 'Active') {
                const remanent = totalPriceCarts(carts, false) - totalPrice;
                setHasMultiplePayments(true);
                setTotalPrice(`$${remanent}`);
                window.location.reload();
                return;
              }
              const tyData = {};
              localStorage.setItem('tyDataCard', JSON.stringify(tyData));
              window.location.replace(`${window.location.origin}/payment/culqi`);
            }
          } else if (firstChargeCreated.status === 201) {
            Culqi3DS.reset();
          } else {
            Culqi3DS.reset();
          }
        } catch (error) {
          setEnableContainer(true);
          setErrorPaymentCulqi('Ocurrió un error al procesar el pago');
          console.error('Error : ', error);
        }
      })();
    }
  }, [culqiToken]);

  useEffect(() => {
    initializeCulqi();
  }, [initializeCulqi]);

  return (
    <>
      {loader && <PageLoader />}
      {!hasAddress && (
        <Banner
          content="Debes ingresar una dirección para continuar con el pago"
          fullWidth
          title=""
          variant="error"
        />
      )}
      {errorPaymentCulqi && (
        <div className={styles.banner}>
          <Banner
            content="Intentalo nuevamente o selecciona un método de pago distinto."
            fullWidth
            title={errorPaymentCulqi}
            variant="critical"
          />
        </div>
      )}
      <div id="culqi-container" className={enableContainer ? styles.enableContainer : styles.disableContainer}></div>
    </>
  );
};

export default CulqiForm;